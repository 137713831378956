<template>
    <client-page>
        <page-section titWrapAlign="text-center" class="page-section--first page-section--last">
            <template slot="tit">로그인</template>

            <div class="mw-588px mx-auto">
                <v-row class="row--xs">
                    <v-col cols="12">
                        <v-text-field v-model="username" v-bind="{ ...attrs_input }" :dense="false" type="email" placeholder="아이디를 입력하세요." />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password" v-bind="{ ...attrs_input }" :dense="false" type="password" name="" value="" placeholder="비밀번호를 입력하세요." @keydown.enter="otp" />
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="remember" color="primary" label="아이디저장" :value="true" hide-details />
                    </v-col>
                </v-row>

                <div class="btn-wrap btn-wrap--sm">
                    <v-row class="row--sm">
                        <v-col cols="12">
                            <v-btn v-bind="{ ...btn_primary }" x-large block @click="otp">로그인하기</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <u-divider-group class="divider-group--dark text-center">
                                <li>
                                    <router-link to="/login/find-id/">아이디 찾기</router-link>
                                </li>
                                <li>
                                    <router-link to="/login/find-password/">비밀번호 찾기</router-link>
                                </li>
                                <li>
                                    <router-link to="/join">회원가입</router-link>
                                </li>
                            </u-divider-group>
                        </v-col>
                    </v-row>
                </div>

                <!-- <div class="btn-wrap btn-wrap--md">
                    <div class="v-divider--center">
                        <span class="v-divider__txt" style="white-space: nowrap;">SNS로 간편 로그인하기</span>
                        <v-divider />
                    </div>
                    <div class="pt-20px pt-md-28px">
                        <v-row class="row--sm">
                            <v-col cols="12">
                                <kakao-login client_id="">카카오톡으로 계속하기</kakao-login>
                            </v-col>
                            <v-col cols="12">
                                <naver-login client_id="">네이버로 계속하기</naver-login>
                            </v-col>
                            <v-col cols="12">
                                <v-btn block color="#1877F2" class="v-size--xx-large"> <i class="icon icon-facebook mr-auto" /><span class="mr-auto white--text">페이스북으로 계속하기</span> </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div> -->
            </div>
        </page-section>
        <login-otp-dialog ref="loginOtpDialog" v-on="{ login }"/>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, attrs_input } from "@/assets/variables";

import CryptoAES from "../../../plugins/crypto-aes";
import KakaoLogin from "../../../components/plugins/kakao/kakao-login.vue";
import NaverLogin from "../../../components/plugins/naver/naver-login.vue";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import UDividerGroup from "@/components/publish/styles/groups/u-divider-group.vue";
import loginOtpDialog from "@/components/client/login/login-otp-dialog.vue";

export default {
    components: {
        KakaoLogin,
        NaverLogin,

        ClientPage,
        PageSection,
        UDividerGroup,
        loginOtpDialog,
    },
    data: () => {
        return {
            btn_primary,
            attrs_input,

            username: "",
            password: "",

            remember: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (this.$cookies.get("username")) this.username = this.$cookies.get("username");
            if (this.$cookies.get("password")) this.password = CryptoAES.decrypt(this.$cookies.get("password"));
        },
        async otp() {
            try {
                await api.v1.users.otp({ username: this.username, password: this.password })
                this.$refs.loginOtpDialog.open()
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        async login(otp) {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember, otp });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$router.push("/");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        }
    },
};
</script>
