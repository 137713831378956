var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" otp ")]), _c('v-card-text', [_c('v-row', [_vm._v(" 가입하신 전화번호로 인증번호 전송해드렸습니다 ")])], 1), _c('v-card-text', [_c('v-row', [_c('v-text-field', {
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("인증하기")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }