<template>
	<v-dialog v-model="show">
		<v-card>
			<v-card-title>
				otp
			</v-card-title>
			<v-card-text>
				<v-row>
					가입하신 전화번호로 인증번호 전송해드렸습니다
				</v-row>
			</v-card-text>
			<v-card-text>
				<v-row>
					<v-text-field v-model="otp"/>
					<v-btn color="primary" @click="login">인증하기</v-btn>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			otp: null
		}
	},
	methods: {
		open() {
			this.show = true;
		},
		close() {
			this.show = false;
		},
		validate() {
			try {
				if (!this.otp) throw new Error("otp 번호를 입력해주세요") 
				return true;
			} catch (error) {
				alert(error.message)
				return false;
			}
		},
		login() {
			if (this.validate()) {
				this.$emit("login", this.otp)
			}
		}
	}
}
</script>

<style>

</style>