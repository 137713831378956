<template>
    <client-page>
        <page-section titWrapAlign="text-center" class="page-section--first page-section--last">
            <template slot="tit">아이디 찾기</template>

            <div class="mw-588px mx-auto">
                <v-row class="row--xs">
                    <v-col cols="12">
                        <v-phone-field v-model="phone" v-bind="{ ...attrs_input, loading }" :dense="false">
                            <template>
                                <v-col cols="12" sm="auto">
                                    <v-btn v-bind="{ ...btn_primar, btnAttrs: {}, dense: true }" x-large color="grey" class="w-100 w-sm-80px" @click="certify">본인인증</v-btn>
                                </v-col>
                            </template>
                        </v-phone-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="otp" v-bind="{ ...attrs_input, loading }" :dense="false" type="number" placeholder="인증번호를 입력하세요." />
                    </v-col>
                </v-row>

                <div class="btn-wrap  btn-wrap--sm">
                    <v-row class="row--sm">
                        <v-col cols="12">
                            <v-btn v-bind="{ ...btn_primary, loading }" x-large block @click="recovery">아이디 찾기</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <u-divider-group class="divider-group--dark text-center">
                                <li>
                                    <router-link to="/login">로그인</router-link>
                                </li>
                                <li>
                                    <router-link to="/login/find-password/">비밀번호 찾기</router-link>
                                </li>
                                <li>
                                    <router-link to="/join">회원가입</router-link>
                                </li>
                            </u-divider-group>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import UDividerGroup from "@/components/publish/styles/groups/u-divider-group.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        VPhoneField,
        UDividerGroup,
    },
    data: () => ({
        btn_primary,
        attrs_input,

        phone: null,
        otp: null,

        loading: false,
    }),
    methods: {
        async certify() {
            try {
                if (!this.phone) alert("핸드폰 번호를 입력해주세요");

                await this.postCertify();

                alert("인증번호가 전송되었습니다");
            } catch (error) {
                console.error(error);
                if (error.response.data.message) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        async recovery() {
            try {
                if (!this.phone) alert("핸드폰 번호를 입력해주세요");
                if (!this.otp) alert("인증번호를 입력해주세요");

                var users = await this.postRecovery();

                var message = users.reduce((message, user) => {
                    return message + `${user.username}\n`;
                }, "[해당 인증정보로 가입된 아이디]\n");

                alert(message);
            } catch (error) {
                console.error(error);
                if (error.response.data.message) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        postCertify() {
            if (this.loading) return;
            else this.loading = true;
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/users/certify", {
                        type: "username",
                        phone: this.phone.phoneNumberFormat(),
                    });

                    resolve();
                } catch (error) {
                    reject(error);
                } finally {
                    this.loading = false;
                }
            });
        },

        postRecovery() {
            if (this.loading) return;
            else this.loading = true;

            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/users/recovery", {
                        type: "username",
                        phone: this.phone.phoneNumberFormat(),
                        otp: this.otp,
                    });

                    resolve(res.data.users);
                } catch (error) {
                    reject(error);
                } finally {
                    this.loading = false;
                }
            });
        },
    },
};
</script>
