var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapAlign": "text-center"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("로그인")]), _c('div', {
    staticClass: "mw-588px mx-auto"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "dense": false,
      "type": "email",
      "placeholder": "아이디를 입력하세요."
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "dense": false,
      "type": "password",
      "name": "",
      "value": "",
      "placeholder": "비밀번호를 입력하세요."
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.otp.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "color": "primary",
      "label": "아이디저장",
      "value": true,
      "hide-details": ""
    },
    model: {
      value: _vm.remember,
      callback: function ($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  })], 1)], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "block": ""
    },
    on: {
      "click": _vm.otp
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("로그인하기")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-divider-group', {
    staticClass: "divider-group--dark text-center"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/login/find-id/"
    }
  }, [_vm._v("아이디 찾기")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/login/find-password/"
    }
  }, [_vm._v("비밀번호 찾기")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1)])], 1)], 1)], 1)], 1)], 2), _c('login-otp-dialog', _vm._g({
    ref: "loginOtpDialog"
  }, {
    login: _vm.login
  }))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }